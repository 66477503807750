<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <h3>Perfil</h3>
    </div>
  </div>

  <div class="grid">
    <!-- Formulário de Alteração do Usuário -->
    <div class="col-12 md:col-5">
      <div class="card fluid">
        <form class="grid" autocomplete="no">

          <div class="md:col-6 pt-4">
            <div class="p-float-label">
              <Password
                id="senha2"
                v-model="senhaNova"
                :feedback="false"
                autocomplete="no"
                class="block field-alterar-senha"
                toggleMask
              ></Password>
              <label for="senha2">Nova Senha</label>
            </div>
          </div>

          <div class="md:col-6 pt-4">
            <div class="p-float-label">
              <Password
                id="senha3"
                v-model="senhaConf"
                :feedback="false"
                autocomplete="no"
                class="block field-alterar-senha"
                toggleMask
              ></Password>
              <label for="senha3">Confirmar Nova Senha</label>
            </div>
          </div>

          <div class="md:col-12">
            <div class="grid">
              <div class="md:col-12">
                <Button
                  id="buttonAlterarUsuario"
                  label="Alterar Usuário"
                  icon="pi pi-check"
                  class="button-success"
                  @click="alterar"
                ></Button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <Toast position="top-right" />
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export default {
  data() {
    return {
      // Campos do Formulário
      senhaNova: "",
      senhaConf: "",
    };
  },

  mounted() {
  },

  methods: {
    alterar() {
      const self = this;

      var erro = false;

      //Alteração de senha
      if (self.senhaNova != "") {
        //Checar se senhas digitadas são iguais
        if (self.senhaNova != self.senhaConf) {
          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: "A Nova Senha e Confirmação da Nova Senha são diferentes!",
            life: 5000,
          });
          erro = true;
        }
      }

      if (erro) return false;

        const user = firebase.auth().currentUser;
        user.updatePassword(self.senhaNova).then(() => {
        
            firebase.database().ref("Usuarios").child(user.uid).update({
                "senha": self.novaSenha,
            }, () => {

                self.$toast.add({
                  severity: "success",
                  summary: "Sucesso!",
                  detail: "Senha alterada com sucesso!",
                  life: 3000,
                });

            });

        }).catch((error) => {

            const errorCode = error.code;
            const errorMessage = error.message;
            
            self.$toast.add({
                severity: "error",
                summary: "ERRO: " + errorCode,
                detail: errorMessage,
                life: 5000,
            });
        });

    },
  },
};
</script>

<style type="text/css">

  .field-alterar-senha input {
    width: 100%;
  }

</style>